$text-color: #444;
$text-color-invert: #fff;
$brand-color: #6dc9ec;
$brand-info: #ff6600;
$transition: all 0.2s ease-in-out;



* {
	outline: 0; }

a {
	&:hover, &:active, &:focus {
		outline: 0; } }



// ------------------------------------------------------------------------- EXTENDS



// ------------------------------------------------------------------------- GLOBAL STYLES
::-moz-selection {
	background: $brand-color;
	color: $text-color-invert;
	text-shadow: none; }

::selection {
	background: $brand-color;
	color: $text-color-invert;
	text-shadow: none; }

body {
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Open Sans', sans-serif;
	color: $text-color; }

body.noHome {
	margin-top: 118px; }

a {
	color: $brand-color;
	text-decoration: none;
	&:hover, &:focus {
		color: darken($brand-color, 15%); } }

.brand-color {
	color: $brand-color; }

.hr-lg {
	margin-top: 40px;
	margin-bottom: 40px; }

.title {
	display: inline-block;
	font-size: 16px;
	font-weight: bold;
	padding: 10px 25px; }

.faq a {
	display: block;
	color: $text-color;
	margin: 15px 0;
	text-decoration: none;
	&:hover {
		color: $brand-color; } }

.bg-info {
	background: #ff6600;
	color: #fff; }

.color-list a {
	color: $text-color;
	&:hover, &:focus {
		color: darken($text-color, 15%); }
	img {
		width: 110px;
		height: 110px; } }

.flickity-prev-next-button:disabled {
	opacity: 0; }

.footer {
	background: url(/images/footer.jpg);
	position: relative;
	padding: 50px 0;
	margin-top: 60px;
	.fa-stack, .fa-rhombus {
		position: absolute;
		left: 50%;
		top: 57px;
		transform: translateX(-50%); }
	.fa-stack {
		top: 57px; }
	.fa-rhombus {
		top: 0px;
		width: 42px;
		height: 42px;
		transform: translate(-50%, -50%) rotate(-45deg);
		margin: 0;
		background: $brand-info;
		border: transparent;
		&:before {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			color: $text-color-invert; } }

	.logo {
		padding: 50px 0; } }

#map-canvas {
	position: relative;
	height: 400px; }



// ------------------------------------------------------------------------- FIGURE
.cover {
	figure {
		position: relative;
		overflow: hidden;
		margin: 50px 0;
		img {
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 0;
			width: 100%; }
		figcaption {
			color: $text-color-invert;
			padding: 100px 0;
			position: relative;
			z-index: 1; } }
	&:hover {
		text-decoration: none; } }



// ------------------------------------------------------------------------- TEXT-ELLIPSIS
$font-size: 14px;
$line-height: 1.428571429;
$lines-to-show: 4;

.text-ellipsis {
	display: block;
	display: -webkit-box;
	height: $font-size*$line-height*$lines-to-show;
	margin: 0 auto;
	font-size: $font-size;
	line-height: $line-height;
	-webkit-line-clamp: $lines-to-show;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis; }



// ------------------------------------------------------------------------- NAV



// ------------------------------------------------------------------------- THUMBNAIl
a.thumbnail {
	text-decoration: none;
	color: $text-color;
	h3 {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis; }
	hr {
		margin-top: 10px;
		margin-bottom: 5px; } }



// ------------------------------------------------------------------------- THUMBNAIl
.gallery {
	margin-bottom: 40px;
	&.gallery-main .gallery-cell {
		width: 100%;
		height: 500px; }
	&.gallery-nav .gallery-cell {
		height: 100px;
		width: 100px; }
	&.gallery-products .gallery-cell {
		width: 135px;
		height: 388px;
		margin-right: 40px; } }

.onDrag .gallery-cell:before {
	content: " ";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 1; }



// ------------------------------------------------------------------------- BUTTON



// ------------------------------------------------------------------------- INPUT
.form-control {
	border-width: 0 0 1px 0;
	box-shadow: none;
	padding-left: 0;
	padding-bottom: 0;
	&:focus {
		box-shadow: none; } }



// ------------------------------------------------------------------------- TABLE



// ------------------------------------------------------------------------- PANEL



// ------------------------------------------------------------------------- CAROUSEL
.carousel .item {
	.blur {
		position: absolute;
		background-size: cover;
		width: 100%;
		height: 113px;
		filter: blur(5px);
		&:before {
			content: '';
			display: block;
			background-color: rgba(0, 0, 0, 0.3);
			position: absolute;
			top: -5px;
			right: -5px;
			bottom: -5px;
			left: -5px; } }
	.carousel-caption {
		left: 50%;
		top: 50%;
		bottom: auto;
		right: auto;
		padding: 0;
		width: 100%;
		transform: translate(-50%, -50%); } }



// ------------------------------------------------------------------------- MEDIA



// ------------------------------------------------------------------------- RESPONSIVE
@media (max-width: 767px) {
	body {
		padding-top: 51px; }
	.navbar-fixed-top {
		background: rgba(0, 0, 0, 0.8);
		.navbar-brand img {
			height: 100%; } }
	.carousel-caption .h1 {
		font-size: 20px;
		letter-spacing: normal; } }



@media (min-width: 768px) {
	.noHome .navbar-fixed-top {
		background: rgba(0, 0, 0, 0.8); }
	.navbar-fixed-top {
		transition: $transition;
		border-image: url(/images/original/navbar-border.png) 1 0;
		border-bottom-width: 5px;
		.navbar-brand {
			transition: $transition;
			width: 175px;
			height: 113px;
			img {
				height: 100%; } }
		.navbar-nav, .navbar-form {
			transition: $transition;
			padding-top: 60px; }
		.navbar-nav {
			font-weight: bold;
			> li > a {
				padding-left: 9px;
				padding-right: 9px; } }
		.navbar-form {
			.form-control {
				border-color: transparent;
				background: none;
				color: $text-color-invert;
				box-shadow: none;
				&::-webkit-input-placeholder {
					color: $text-color-invert; }
				&:-moz-placeholder {
					color: $text-color-invert; }
				&::-moz-placeholder {
					color: $text-color-invert; }
				&:-ms-input-placeholder {
					color: $text-color-invert; }
				&:focus {
					box-shadow: none;
					border-color: $text-color-invert; } }
			.btn-default {
				background: none;
				border-color: transparent;
				color: $text-color-invert; } }
		.navbar-address {
			position: relative;
			margin: 0;
			.inner {
				//text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5)
				color: $text-color-invert;
				position: absolute;
				right: 0;
				top: 20px;
				font-size: 16px;
				//font-style: italic
				i {
					margin-left: 10px; } } } }
	&.affix {
		background-image: url("/images/menu_bg.png") !important;
		background-position: center !important;
		background-color: rgba(0, 0, 0, 0.8) !important;
		.navbar-brand {
			height: 50px;
			width: 65px; }
		.navbar-nav, .navbar-form {
			padding-top: 0; }
		.navbar-address {
			display: none; } }
	.dropdown-products {
		position: static !important;
		.dropdown-menu {
			top: calc(100% + 5px);
			left: 0;
			right: 0;
			background: rgba(0, 0, 0, 0.8);
			text-align: center;
			> li {
				display: inline-block;
				> a {
					padding: 20px 30px;
					color: $text-color-invert;
					font-weight: bold;
					img {
						margin-bottom: 10px; }
					&:hover, &:focus {
						color: $brand-color;
						background-color: transparent; } } } } }
	.carousel-caption .h1 {
		font-size: 40px;
		letter-spacing: -3px; }
	.footer .navbar-nav > li > a:hover {
		background: none; } }



@media (min-width: 992px) {
	.carousel-caption .h1 {
		font-size: 60px;
		letter-spacing: -5px; }
	.navbar-fixed-top .navbar-nav > li > a {
		padding-left: 8px;
		padding-right: 8px; } }



@media (min-width: 1200px) {
	.carousel-caption .h1 {
		font-size: 80px;
		letter-spacing: -5px; }
	.navbar-fixed-top .navbar-nav {
		font-size: 17px;
		> li > a {
			padding-left: 15px;
			padding-right: 15px; } } }
